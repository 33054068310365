#header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#money-sign-button {
  margin-inline: 15px;
}

#user-button {
  margin-right: 10px;
}
